<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div v-if="data" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Editar Usuário</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="save" class="form-horizontal">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <label class="control-label col-md-6 text-left-m"
                  >System ID</label
                >
                <div class="col-md-6">
                  <input
                    v-model="form.system_id"
                    type="text"
                    class="form-control"
                    minlength="8"
                    maxlength="16"
                    disabled
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label col-md-6 text-left-m"
                  >{{$t('generic-str.password')}}</label
                >
                <div class="col-md-6">
                  <input
                    v-model="form.newPassword"
                    type="text"
                    class="form-control"
                    minlength="4"
                    maxlength="8"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-6">
                  <label for="">{{$t('options-div.lbl-replace-chars')}}</label>
                </div>
                <div class="col-6">
                  <div class="control-group opt-2">
                    <label class="control control-checkbox">
                      <input id="checkbox" v-model="form.normalize" type="checkbox" />
                    <div class="control_indicator"></div>
                    </label>
                  </div>
                </div>
                <!-- <label class="control-label col-md-6 text-left-m"
                  >{{$t('options-div.lbl-replace-chars')}}</label
                >
                <div class="col-md-6">
                  <input type="checkbox" id="checkbox" v-model="form.normalize" style="margin-top:10px;">
                </div> -->
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-primary" @click="hide">
              {{$t('generic-str.cancel')}}
            </button>
            <button
              :disabled="isSending"
              type="submit"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              class="btn btn-primary"
            >
              {{$t('generic-str.save')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SmsService from '@/services/sms.service';
import Modal from '@/mixins/Modal';

export default {
  components: {
  },
  mixins: [Modal],
  props: ['id'],
  data() {
    return {
      isSending: false,
      form: {},
    };
  },
  mounted() {
    // this.fetchGroups();
  },
  methods: {
    save() {
      this.isSending = true;
      SmsService.editUser(this.form).then(
        () => {
          this.hide();
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Usuário atualizado',
            type: 'success',
          });
          this.$emit('sent');
          this.form = {};
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.response.data.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
  },
};
</script>
