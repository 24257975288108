<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="'SMS'" :subtitle="this.$t('sms.smpp-users.header')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card smpp-card">
            <div class="card-header">
              <h2 class="card-title col-12 col-sm-2">{{ $tc('generic-str.acc', 2) }}</h2>
              <div class="col-lg-6 search-column">
                <div class="filter-search">
                  <form @submit.prevent="search">
                    <div class="input-group">
                      <input
                        placeholder=""
                        type="text"
                        class="form-control"
                        :v-model="searchQuery"
                        ref="searchInput"
                      />
                      <div class="input-group-prepend">
                        <button
                          type="button"
                          class="btn icon_btn"
                          @click="search"
                        >
                          <span class="material-symbols-outlined">
                            search
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-lg opt-smpp">
                <button v-modal="{ target: 'smpp-m' }" class="btn btn-add-person emit-j-event"><span class="material-icons add_person">person_add</span></button>
                <button class="btn btn-delete"
                  @click="deleteAll"
                  v-bind:disabled="selectedUsers.length == 0">
                  <span class="material-symbols-outlined title">delete</span><!-- Excluir -->
                </button>
              </div>
            </div>
            <div class="card-body block-el p-0">
              <div class="responsive-table">
                <div
                  v-if="!users.length && fetched"
                  class="text-center table-placeholder"
                >
                  <!-- <i class="fas fa-cog font-size-80"></i> -->
                  <LottieAnimNoData />
                  <h5 class="card-title m-t-20">
                    Nenhuma conta SMPP cadastrada
                  </h5>
                </div>
                <table v-if="fetched" class="table table-smpp">
                  <thead v-if="users.length" class="">
                    <tr>
                      <th class="w-60" scope="col">
                        <div class="control-group opt-2">
                          <label
                            class="control control-checkbox select-all-checkbox"
                            for="customCheck1"
                          >
                            <input
                              v-model="selectAllUsers"
                              type="checkbox"
                              class="custom-control-input"
                              id="customCheck1"
                            />
                            <div class="control_indicator"></div>
                          </label>
                        </div>
                      </th>
                      <th scope="col">SYSTEM ID</th>
                      <th scope="col">{{$t('sms.smpp-users.special-char')}}</th>
                      <th scope="col">Status</th>
                      <th scope="col">TX</th>
                      <th scope="col">RX</th>
                      <th scope="col">TRX</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(user, i) in users" :key="user.id">
                      <td data-label>
                        <div class="control-group opt-2">
                          <label
                            class="control control-checkbox select-all-checkbox"
                            :for="'user' + i"
                          >
                            <input
                              v-model="selectedUsers"
                              type="checkbox"
                              class="custom-control-input"
                              :id="'user' + i"
                              :value="user"
                            />
                            <div class="control_indicator"></div>
                          </label>
                        </div>
                      </td>
                      <td data-label="SYSTEM ID">
                        {{ user.system_id }}
                      </td>
                      <td data-label="C. Especiais">
                        <span v-if="user.normalize" class="material-symbols-outlined icon-done">done</span>
                        <span v-else class="material-symbols-outlined icon-not-done">close</span>
                      </td>
                      <td data-label="Status">
                        <span class="material-symbols-outlined icon-success" v-if="user.active">task_alt</span>
                        <span class="material-symbols-outlined icon-failed" v-if="!user.active">unpublished</span>
                      </td>
                      <td data-label="TX">
                        {{ user.tx ? user.tx : $t('app.not-conected') }}
                      </td>
                      <td data-label="RX">
                        {{ user.rx ? user.rx : $t('app.not-conected') }}
                      </td>
                      <td data-label="TRX">
                        {{ user.trx ? user.trx : $t('app.not-conected') }}
                      </td>
                      <td data-label>
                        <dropdown>
                          <template v-slot:text>
                            <span class="material-symbols-outlined title">
                              more_vert
                            </span>
                          </template>
                          <template #items>
                            <button
                              v-modal="{
                                target: 'create-smpp-m',
                                data: user,
                              }"
                              class="dropdown-item"
                            ><span class="material-symbols-outlined title mr-2">drive_file_rename_outline</span>
                              {{ $t('generic-str.edit') }}
                            </button>
                            <!-- <button class="dropdown-item">
                              <span class="material-symbols-outlined mr-2">person_off</span>
                            {{$t('app.block')}}</button> -->
                            <button
                              class="dropdown-item"
                              @click="remove(user.id)"
                            ><span class="material-symbols-outlined title mr-2">delete_forever</span>
                              {{ $t('generic-str.remove') }}
                            </button>
                            <!-- <hr/>
                            <span class="px-2">{{$t('sms.smpp-users.change-dep')}}</span>
                            <button class="dropdown-item">{{$t('home.invite-card.select.fin')}}</button>
                            <button class="dropdown-item">Marketing</button>
                            <button class="dropdown-item">{{$t('home.invite-card.select.commercial')}}</button>
                            <button class="dropdown-item">Onboarding</button>
                            <button class="dropdown-item">{{$t('home.invite-card.select.dev')}}</button> -->
                          </template>
                        </dropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                />
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <create-user-modal
      id="create-user"
      @sent="fetch(form.page)"
    ></create-user-modal> -->
    <create-smpp-modal id="smpp-m" @sent="fetch(form.page)"></create-smpp-modal>
    <smpp-modal id="create-smpp-m" @sent="fetch(form.page)"></smpp-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import SmsService from '@/services/sms.service';
import SmppModal from '@/components/rebranding/smpp/SmppModal.vue';
import CreateSmppModal from '@/components/rebranding/smpp/CreateSmppModal.vue';
import Pagination from '@/components/Pagination.vue';
import Swal from 'sweetalert2';
import Dropdown from '@/components/Dropdown.vue';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';

export default {
  name: 'ListUser',
  components: {
    PageHeader,
    Pagination,
    SmppModal,
    CreateSmppModal,
    Dropdown,
    LottieAnimNoData,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/sms/reports/stat',
        2: '/sms/smpp',
      },

      searchQuery: null,
      fetched: false,
      users: [],
      form: {
        name: '',
        page: 1,
      },
      pages: 1,
      selectedUsers: [],
    };
  },
  computed: {
    selectAllUsers: {
      get() {
        if (this.users) {
          return this.selectedUsers.length === this.users.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.users.forEach((user) => {
            selected.push(user);
          });
        }

        this.selectedUsers = selected;
      },
    },
  },
  created() {
    this.fetch();
  },
  mounted() {
    this.$root.$on('smpp.send', this.fetch);
  },
  methods: {
    search() {
      this.fetched = false;
      const word = this.$refs.searchInput.value;
      // console.log('Query: ', word);
      let result = null;
      SmsService.getUsers(this.form).then(
        (response) => {
          this.fetched = true;
          this.users = response;
          // result = this.users.filter((item) => word.toLowerCase().split(' ').every((v) => item.system_id.toLowerCase().includes(v)));
          result = this.users.filter((item) => word.toLowerCase().split(' ').every((v) => item.system_id.toLowerCase().includes(v)));
          this.users = result;
          /* this.users = response;
          this.pages = response.last_page; */
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetch() {
      this.fetched = false;
      SmsService.getUsers(this.form).then(
        (response) => {
          this.fetched = true;
          this.users = response;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    remove(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: 'O usuário será excluído permanentemente. Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          SmsService.deleteUser(id).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(
                `${this.$t('generic-str.deleted')}!`,
                'Seu usuário foi excluído.',
                'success',
              );
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    deleteAll() {
      Swal.fire({
        title: 'Remover usuários',
        text: 'Tem certeza que gostaria de remover os usuários?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedUsers.forEach((item) => {
            toDelete.push(item.id);
            // console.info('To delete: ', toDelete);
          });
          SmsService.deleteUsers({ ids: toDelete }).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(
                `${this.$t('generic-str.deleted')}!`,
                'Seus usuários foram excluídos.',
                'success',
              );
              this.selectedUsers = [];
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                  error.code
                }`,
                content: error.response.data.message,
                type: 'danger',
              });
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.custom-control-label {
  &::after,
  &::before {
    top: -0.5rem;
  }
}
.btn i {
  line-height: 1;
  color: inherit;
}
.btn {
  margin-bottom: 5px;
}

/* Rebranding */
.smpp-card .card-header {
  display: flex;
  gap: 10px;
}

.smpp-card .card-title {
  font-weight: 400;
  font-size: 1.25rem;
}

.filter-search {
  max-width: 600px;
  width: inherit;

  input {
    border-right-color: var(--form-input) !important;
  }
}

.filter-search .btn {
  padding: 0;
}

.filter-search ~ .input-group {
  justify-content: flex-end;
}

.icon_btn {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--form-border);
  border-left-color: var(--form-input);
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-right: 15px !important;

  span {
    color: var(--gray-font-color);
  }
}

.darkmode .icon_btn {
  background-color: var(--form-input);
}

.btn-add-person {
  height: fit-content;
}

.table-smpp td, .table-smpp th, .table-smpp thead th {
  border-bottom: 1px solid transparent;
}

.table-smpp .control-group.opt-2 {
  width: fit-content;
  margin: auto;
}

.table-smpp::v-deep .dropdown.show .dropdown-menu.show {
  width: max-content !important;
}

.table-smpp .dropdown button {
  display: flex;
  align-items: center;
}

.table-smpp .dropdown button.dropdown-item {
  color: var(--gray-font-color);
}

.table-smpp .dropdown hr {
  margin-bottom: .5rem;
}

.table-smpp .dropdown hr ~ span {
  font-weight: 400;
  color: var(--gray-font-color-3);
}

.icon-done {
  color: var(--clr-yup-purple);
}

.icon-not-done {
  color: var(--gray-font-color-2);
}

.icon-success {
  color: #6eda2c;
}

.icon-failed {
  color: #ff0000;
}

.table-smpp tr th:first-child {
  width: 5%;
}
.table-smpp tr th:nth-child(2), .table-smpp tr td:nth-child(2) {
  width: 25%;
  text-align: left;
}
.table-smpp tr th:nth-child(3) {
  width: 20%;
}
.table-smpp tr th:nth-child(4) {
  width: 20%;
}
@media (min-width: 1360px) and (max-width: 1600px) {
   .table-smpp tr th:nth-child(4) {
    width: 10%;
  }
}
.search-column {
  display: flex;
  align-items: center;
}
.opt-smpp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rebranding .add_person {
  font-size: 24px;
}

.btn-delete {
  padding: 5px;
  height: fit-content;
  max-height: 35px;
  span{
    font-size: 24px;
  }
}
</style>
